<template>
  <div>
    <!-- banner -->
    <div class="banner" :style="cover">
      <h1 class="banner-title">说说</h1>
    </div>
    <!-- 说说内容 -->
    <v-card class="blog-container">
      <div class="talk-wrapper">
        <!-- 用户信息 -->
        <div class="user-info-wrapper">
          <v-avatar size="36" class="user-avatar">
            <img src="@/assets/img/head.jpg"/>
          </v-avatar>
          <div class="user-detail-wrapper">
            <div class="user-nickname">
              {{ talkInfo.nickname }}
              <v-icon class="user-sign" size="20" color="#ffa51e">
                mdi-check-decagram
              </v-icon>
            </div>
            <!-- 发表时间 -->
            <div class="time">{{ talkInfo.createTime }}</div>
            <!-- 说说信息 -->
            <div class="talk-content" v-html="talkInfo.content"/>
            <!-- 图片列表 -->
            <v-row class="talk-images" v-if="talkInfo.images">
              <v-col
                  :md="4"
                  :cols="6"
                  v-for="(img, index) of talkInfo.images"
                  :key="index"
              >
                <v-img v-if="img.indexOf('mp4')===-1"
                       class="images-items"
                       :src="img"
                       aspect-ratio="1"
                       max-height="200"
                       @click="previewImg(index)"
                />
                <video v-if="img.indexOf('mp4')>-1"
                       ref="myVideo"
                       :src="img"
                       :controls="true"
                       oncontextmenu="return false"
                       controlslist="nodownload"
                       class="video-box"
                ></video>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Comment from "../../components/Comment";
import {getTalkInfo} from "../../api";
import {addSuffix} from "../../utils/common";

export default {
  components: {
    Comment
  },
  created() {
    this.getTalkById();
  },
  data: function () {
    return {
      commentType: 3,
      commentCount: 0,
      talkInfo: {},
      previewList: []
    };
  },
  methods: {
    getTalkById() {
      getTalkInfo({
        talkId: this.$route.params.talkId
      }).then(({data}) => {
        this.talkInfo = data;
        if (this.talkInfo.images) {
          //将图片分解为数组
          let newImages = [];
          let oriImages = [];
          this.talkInfo.images.substr(1, this.talkInfo.images.length - 2).split(',').forEach(item => {
            let s = item.replaceAll('"', '').replaceAll('\'', '');
            newImages.push(addSuffix(s));
            oriImages.push(s);
          })
          this.talkInfo.images = newImages;
          //预览列表
          this.previewList.push(...oriImages);
        }
      });
    },
    getCommentCount(count) {
      this.commentCount = count;
    },
    previewImg(index) {
      this.$hevueImgPreview({
        multiple: true,
        nowImgIndex: index,
        imgList: this.previewList
      })
    },
  },
  computed: {
    cover() {
      var cover = "";
      this.$store.state.blogInfo.pageList.forEach(item => {
        if (item.pageLabel == "talk") {
          cover = item.pageCover;
        }
      });
      return "background: url(" + cover + ") center center / cover no-repeat";
    },
  }
};
</script>

<style scoped>
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  width: 100%;
  padding: 2px !important;
}

.talk-wrapper {
  padding: 16px 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 3px 8px 6px rgb(7 17 27 / 6%);
  transition: all 0.3s ease 0s;
}

.talk-wrapper:hover {
  box-shadow: 0 5px 10px 8px rgb(7 17 27 / 16%);
  transform: translateY(-3px);
}

.user-info-wrapper {
  width: 100%;
  display: flex;
}

.user-avatar {
  border-radius: 50%;
}

.user-avatar {
  transition: all 0.5s;
}

.user-avatar:hover {
  transform: rotate(360deg);
}

.user-detail-wrapper {
  margin-left: 10px;
  width: 100%;
  flex: 1;
  width: 0;
}

.user-nickname {
  font-size: 15px;
  font-weight: bold;
  vertical-align: middle;
}

.user-sign {
  margin-left: 4px;
}

.time {
  color: #999;
  margin-top: 2px;
  font-size: 12px;
}

.talk-content {
  margin-top: 8px;
  font-size: 14px;
  white-space: pre-line;
  word-wrap: break-word;
  word-break: break-all;
}

.talk-images {
  padding: 0 10px;
  margin-top: 8px;
}

.images-items {
  cursor: pointer;
  border-radius: 4px;
}

.talk-operation {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.talk-operation-item {
  display: flex;
  align-items: center;
  margin-right: 40px;
  font-size: 12px;
}

.operation-count {
  margin-left: 4px;
}

.load-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.load-wrapper button {
  background-color: #49b1f5;
  color: #fff;
}

.comment-wrapper {
  margin-top: 20px;
}

.like-btn:hover {
  color: #eb5055 !important;
}

.video-box {
  box-sizing: border-box;
  border: 0;
  display: block;
  width: 100%;
  height: 100%;
  outline: none !important;
}
</style>
